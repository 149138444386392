<script setup>
import { ref, onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import Loader from 'sja.vue.libraries.charts.Loader.vue';

let series = ref([]);
let categories = ref([]);
let loaded = ref(false);

const dsSJAOrgUnit = getOrCreateDataObject({
    id: 'dsSJAOrgUnit',
    viewName: 'aviw_SJA_SJA',
    maxRecords: -1,
    whereClause: `Workflow_Deleted IS NULL`,
    distinctRows: false,
    selectFirstRowOnLoad: true,
    fields:
        [
            { name: "OrgUnit" },
            { name: "OrgUnitIdPath" },
            { name: "Completed" },
            { name: "Step_ID" },
            { name: "Workflow_Deleted" },
        ]
});

dsSJAOrgUnit.enableContextFilter();

dsSJAOrgUnit.on('DataLoaded', async (data, options) => {
    
    categories.value = [
        ...new Set(dsSJAOrgUnit.data.map((element) => element.OrgUnit)),
    ];

    series.value = [
        { name: 'Completed', data: categories.value.map(value => dsSJAOrgUnit.data.filter(row => row.OrgUnit === value && row.Completed).length) },
        { name: 'Planned', data: categories.value.map(value => dsSJAOrgUnit.data.filter(row => row.OrgUnit === value && !row.Completed).length) }
    ];

    await createChart()
    loaded.value = true
})
onMounted(async () => {
    await dsSJAOrgUnit.load();
})

function createChart() {
    const mergedOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: $t(`SJA's per Org Unit`)
        },
        xAxis: {
            categories: categories.value
        },
        yAxis: {
            min: 0,
            title: {
                text: $t(`Total SJA's`)
            },
            stackLabels: {
                enabled: true
            }
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer'
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: series.value
    };

    Highcharts.chart('orgunitChartContainer', mergedOptions);
};
</script>

<template>
    <div id="orgunitChartContainer" class="p-2" style="width: 100%">
        <Loader :loaded="loaded"></Loader>
    </div>
</template>